import colorutil from "color-util/src/ColorUtil";

/*
 * The getComputedColors function retrieves the computed styles for the application.
 * Used for get css variable value and provide this for charts
 * */

export const getComputedColors = () => {
  let style = getComputedStyle(document.getElementById("app"));
  const variablesList = [
    "text-primary",
    "text-secondary",
    "text-tertiary",
    "text-subtitle",
    "text-disabled",
    "text-accent",
    "text-white",
    "text-black",
    "bg-primary",
    "bg-secondary",
    "bg-tertiary",
    "border-primary",
    "border-secondary",
    "border-tertiary",
    "bg-active",
    "bg-hover",
    "btn-primary",
    "btn-secondary",
    "btn-ghost-hover",
    "btn-primary-hover",
    "btn-secondary-hover",
    "bg-brand",
    "bg-brand-contrast",
    "bg-green",
    "bg-yellow",
    "bg-red",
    "text-green",
    "text-yellow",
    "text-red",
    "graphics-1-blue",
    "graphics-2-orange",
    "graphics-3-green",
    "graphics-4-red",
    "graphics-5-purple",
    "graphics-6-brown",
    "graphics-7-pink",
    "graphics-8-gray",
    "graphics-9-yellow",
    "graphics-10-light-blue",
    "graphics-12-burgundy",
    "graphics-13-cobalt",
    "graphics-14-khaki",
    "graphics-15-brown",
    "graphics-16-grey-blue",
    "border-input",
    "text-contrast",
    "btn-primary-disabled",
    "btn-secondary-disabled",
    "bg-input",
    "bg-card-white",
    "bg-color",
    "bg-card-red",
    "bg-card-yellow",
    "status-green",
    "status-red",
    "status-orange",
    "status-pink",
    "status-cyan",
    "status-grass-green",
    "status-violet",
    "status-blue",
    "status-dark-blue",
    "status-yellow",
    "status-grey-blue"
  ];

  const colors = Object.keys(variablesList).reduce(
    (acc, index) => ({
      ...acc,
      [variablesList[index]]: colorutil.color(
        style.getPropertyValue("--" + variablesList[index]).trim()
      )
    }),
    {}
  );

  return {
    textPrimary: colors["text-primary"],
    textSecondary: colors["text-secondary"],
    textTertiary: colors["text-tertiary"],
    textSubtitle: colors["text-subtitle"],
    textDisabled: colors["text-disabled"],
    textAccent: colors["text-accent"],
    textWhite: colors["text-white"],
    textBlack: colors["text-black"],
    bgPrimary: colors["bg-primary"],
    bgSecondary: colors["bg-secondary"],
    bgTertiary: colors["bg-tertiary"],
    borderPrimary: colors["border-primary"],
    borderSecondary: colors["border-secondary"],
    borderTertiary: colors["border-tertiary"],
    bgActive: colors["bg-active"],
    bgHover: colors["bg-hover"],
    btnPrimary: colors["btn-primary"],
    btnSecondary: colors["btn-secondary"],
    btnGhostHover: colors["btn-ghost-hover"],
    btnPrimaryHover: colors["btn-primary-hover"],
    btnSecondaryHover: colors["btn-secondary-hover"],
    bgBrand: colors["bg-brand"],
    bgBrandContrast: colors["bg-brand-contrast"],
    bgGreen: colors["bg-green"],
    bgYellow: colors["bg-yellow"],
    bgRed: colors["bg-red"],
    textGreen: colors["text-green"],
    textYellow: colors["text-yellow"],
    textRed: colors["text-red"],
    graphics1Blue: colors["graphics-1-blue"],
    graphics2Orange: colors["graphics-2-orange"],
    graphics3Green: colors["graphics-3-green"],
    graphics4Red: colors["graphics-4-red"],
    graphics5Purple: colors["graphics-5-purple"],
    graphics6Brown: colors["graphics-6-brown"],
    graphics7Pink: colors["graphics-7-pink"],
    graphics8Gray: colors["graphics-8-gray"],
    graphics9Yellow: colors["graphics-9-yellow"],
    graphics10LightBlue: colors["graphics-10-light-blue"],
    graphics10Cyan: colors["graphics-10-cyan"],
    graphics12Burgundy: colors["graphics-12-burgundy"],
    graphics13Cobalt: colors["graphics-13-cobalt"],
    graphics14Khaki: colors["graphics-14-khaki"],
    graphics15Cobalt: colors["graphics-15-brown"],
    graphics16Khaki: colors["graphics-16-grey-blue"],
    borderInput: colors["border-input"],
    textContrast: colors["text-contrast"],
    btnPrimaryDisabled: colors["btn-primary-disabled"],
    btnSecondaryDisabled: colors["btn-secondary-disabled"],
    bgInput: colors["bg-input"],
    bgCardWhite: colors["bg-card-white"],
    bgColor: colors["bg-color"],
    bgCardRed: colors["bg-card-red"],
    bgCardYellow: colors["bg-card-Yellow"],
    statusGreen: colors["status-green"],
    statusRed: colors["status-red"],
    statusOrange: colors["status-orange"],
    statusPink: colors["status-pink"],
    statusCyan: colors["status-cyan"],
    statusGrassGreen: colors["status-grass-green"],
    statusViolet: colors["status-violet"],
    statusBlue: colors["status-blue"],
    statusDarkBlue: colors["status-dark-blue"],
    statusYellow: colors["status-yellow"]
  };
};
