var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"slide-fade"}},[_c('div',{staticClass:"opportunity_cost_container"},[_c('div',{staticClass:"metric-component auto-opportunity-cost"},[_c('div',{staticClass:"title"},[_c('h2',{staticClass:"card-title text-subtitle-2 color--text-primary text-uppercase font-weight-medium mb-3"},[_vm._v("\n          "+_vm._s(_vm.metric.node.name)+"\n        ")])]),_vm._v(" "),(!_vm.loaded)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_c('div',{staticClass:"charts"},[(_vm.loaded)?_c('div',{staticClass:"mb-5 text-h5 color--text-primary font-weight-medium"},[_vm._v("\n          "+_vm._s("$")+_vm._s(Math.round(_vm.total_opportunityCost))+"\n        ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"charts_container"},[_c('div',{staticClass:"chart"},[_c('BarChart',{staticClass:"dynamicChart",attrs:{"uom":"$","chart-id":_vm.metric.node.id,"chart-name":_vm.metric.node.name,"chart-data":_vm.chart_data[_vm.metric.node.name],"chart-options":{
                scales: {
                  yAxes: [{ zeroLineColor: '#FFF', stacked: true }],
                  xAxes: [
                    {
                      ticks: { minRotation: 90, maxRotation: 90 },
                      stacked: true
                    }
                  ]
                }
              }}})],1)])])],1),_vm._v(" "),_c('div',{staticClass:"metric-component shiftTableContainer"},[_c('div',{staticClass:"chart"},[_c('v-data-table',{attrs:{"headers":_vm.shiftSummary.headers,"items":_vm.shiftSummary.shifts,"loading":!_vm.loaded,"height":"100%","disable-pagination":"","fixed-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex text-no-wrap align-center"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":item.color}},[_vm._v("mdi-checkbox-blank")]),_vm._v("\n              "+_vm._s(item.name)+"\n            ")],1)]}},{key:"item.cost",fn:function(ref){
              var item = ref.item;
return [_c('div',[_vm._v("\n              "+_vm._s("$".trim())+_vm._s(_vm.numberWithCommas(item.cost ? item.cost.toFixed(2) : 0))+"\n            ")])]}},{key:"item.percentage",fn:function(ref){
              var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.getPercentage(item))+_vm._s("%".trim()))])]}}],null,true)})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }