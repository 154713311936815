<template>
  <div>
    <div
      v-for="(metric, edgesIndex) in trend.metrics.edges"
      :key="edgesIndex"
      class="chart"
    >
      <!-- <h2 class="auto-title">{{ metric.node.name }}</h2> -->
      <div class="row_container">
        <AutoQualityTrend
          v-if="metric.node.automatic == 'QUALITY'"
          :metric="metric"
          :machine_group_pk="machine_group_pk"
          :time-scale="timeScale"
          class="w-50 chart-card"
          v-on="$listeners"
        />
        <AutoOpportunityCost
          v-if="metric.node.automatic == 'QUALITY_OPPORTUNITY_COST'"
          :metric="metric"
          :machine_group_pk="machine_group_pk"
          :measurements="['quality_variance']"
          :time-scale="timeScale"
          class="chart-card"
          v-on="$listeners"
        />
        <AutoOpportunityCost
          v-if="metric.node.automatic == 'PERFORMANCE_OPPORTUNITY_COST'"
          :metric="metric"
          :machine_group_pk="machine_group_pk"
          :measurements="['performance_variance']"
          :time-scale="timeScale"
          class="chart-card"
          v-on="$listeners"
        />
        <AutoOpportunityCost
          v-if="metric.node.automatic == 'OEE_OPPORTUNITY_COST'"
          :metric="metric"
          :machine_group_pk="machine_group_pk"
          :measurements="['quality_variance', 'performance_variance', 'availability_variance']"
          :time-scale="timeScale"
          class="chart-card"
          v-on="$listeners"
        />
        <AutoOpportunityCost
          v-if="metric.node.automatic == 'SCRAP_COST'"
          :metric="metric"
          :machine_group_pk="machine_group_pk"
          :measurements="['scrap_cost']"
          :time-scale="timeScale"
          class="chart-card"
          v-on="$listeners"
        />
        <AutoOEE
          v-if="metric.node.automatic == 'OEE'"
          :metric="metric"
          :machine_group_pk="machine_group_pk"
          :time-scale="timeScale"
          class="w-50 chart-card"
          v-on="$listeners"
        />
        <AutoPerformance
          v-if="metric.node.automatic == 'PERFORMANCE'"
          :metric="metric"
          :machine_group_pk="machine_group_pk"
          :time-scale="timeScale"
          class="w-50 chart-card"
          v-on="$listeners"
        />
        <ManualTrend
          v-if="metric.node.automatic == null || metric.node.automatic == ''"
          :metric="metric"
          :machine_group_id="machine_group_id"
          :time-scale="timeScale"
          class="chart-card"
          v-on="$listeners"
        />
        <TopPartCost
          v-if="
            metric.node.automatic == 'QUALITY' ||
            metric.node.automatic == 'PERFORMANCE' ||
            metric.node.automatic == 'OEE'
          "
          :metric="metric"
          :machine_group_pk="machine_group_pk"
          :time-scale="timeScale"
          class="w-50 chart-card"
          v-on="$listeners"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AutoOEE from "./ZoneControlAutoOEE.vue";
import AutoOpportunityCost from "./ZoneControlAutoOpportunityCost.vue";
import AutoPerformance from "./ZoneControlAutoPerformance.vue";
import AutoQualityTrend from "./ZoneControlAutoQualityTrend.vue";
import ManualTrend from "./ZoneControlManualTrend.vue";
import TopPartCost from "./ZoneControlTopPartCost.vue";

export default {
  components: {
    AutoPerformance,
    AutoOEE,
    AutoOpportunityCost,
    AutoQualityTrend,
    ManualTrend,
    TopPartCost
  },
  props: [`trend`, "timeScale"],
  computed: {
    machine_group_pk() {
      return this.$route.params.machine_group_pk;
    },
    machine_group_id() {
      return this.$route.params.machine_group_id;
    }
  },
  created() {},
  methods: {}
};
</script>

<style scoped lang="scss">
@import "~@/scss/variables";
@import "~@/scss/mq";

.chart {
  margin: 0 0 16px;
}

.row_container {
  position: relative;
  display: flex;
  gap: 12px 16px;
}

.chart-card {
  background-color: var(--bg-card) !important;
  padding: 16px 20px;
  border-radius: 8px;
}

.w-50 {
  width: 50%;
}

@include media_below(1200px) {
  .w-50 {
    width: 100%;
  }
}
</style>
