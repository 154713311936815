<template>
  <div
    key="ps"
    class="problem-solving"
  >
    <div class="issueTables">
      <div
        v-for="(issueTable, tableIndex) in issueTables"
        :key="tableIndex"
        class="card"
      >
        <div class="card-header">
          <h3>{{ issueTable.title }}</h3>
          <div class="headerBtns d-flex">
            <zone-issue
              v-if="tableIndex == 0"
              :machine_group_id="machine_group_id"
              :machine_group_pk="machine_group_pk"
              @submitted="(...args) => addIssue(issueTable.issues, ...args)"
            />
            <v-btn
              class="expand ml-2"
              small
              light
              color="white"
              fab
              @click.stop.prevent="setEnlargedTable({ ...issueTable })"
            >
              <i
                class="fa fa-expand"
                aria-hidden="true"
              ></i>
            </v-btn>
          </div>
        </div>
        <div class="card-content">
          <div class="values">
            <div class="item">
              <p class="label">{{ $t("Total Open") }}</p>
              <p>{{ issueTable.total }}</p>
            </div>
            <div class="item">
              <p class="label">{{ $t("In Process") }}</p>
              <p>{{ getInprocess(issueTable.issues) }}</p>
            </div>
            <div class="item">
              <p class="label">{{ $t("Latest") }}</p>
              <p>{{ getLatest(issueTable.issues) }}</p>
            </div>
            <div class="item">
              <p class="label">{{ $t("Oldest") }}</p>
              <p>{{ getOldest(issueTable.issues) }}</p>
            </div>
          </div>
          <v-data-table
            :headers="issueHeaders"
            :items="issueTable.issues"
            :loading="issueTable.loading"
            :page.sync="issueTable.current"
            :items-per-page.sync="issueTable.itemsPerPage"
            :server-items-length="issueTable.total"
            :footer-props="{ 'items-per-page-options': [5, 10, 25, 50] }"
            item-key="key_as_string"
            class="issue-data-table"
            disable-pagination
            fixed-header
            multi-sort
            @pagination="(...args) => loadIssuePage(issueTable, ...args)"
            @update:items-per-page="handleItemsPerPageChange($event, issueTable)"
          >
            <template #[`item.status`]="{ item }">
              <i
                class="harveyBall mdi"
                :class="getStatusIcon(item)"
                aria-hidden="true"
              ></i>
            </template>
            <template #[`item.relation`]="{ item }">
              <p>
                {{ item.node.machineName ? item.node.machineName : item.node.machineGroupName }}
              </p>
            </template>
            <template #[`item.reason`]="{ item }">
              <p>
                {{ item.node.reason.substring(0, 20)
                }}{{ item.node.reason.length > 20 ? "...".trim() : "" }}
              </p>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ZoneControlProblemSolving",
  props: {
    machine_group_id: { type: String, default: "" },
    machine_group_pk: { type: [Number, String], default: "" },
    issueTables: { type: Array, default: () => [] },
    issueHeaders: { type: Array, default: () => [] },
    setEnlargedTable: { type: Function, default: () => null },
    loadIssuePage: { type: Function, default: () => null },
    handleItemsPerPageChange: { type: Function, default: () => null },
    getStatusIcon: { type: Function, default: () => null },
    getInprocess: { type: Function, default: () => null },
    getLatest: { type: Function, default: () => null },
    getOldest: { type: Function, default: () => null },
    addIssue: { type: Function, default: () => null }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_table.scss";

.card {
  min-height: 250px;

  & ~ .card {
    margin-top: 1rem;
  }
}

.card .card-header {
  position: relative;
}

.problem-solving .issueTables .card .card-content .values {
  min-width: 120px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem 0;
}

.problem-solving .issueTables .card .card-content .values .item {
  display: flex;
  gap: 4px;
  margin-bottom: 20px;
}

.problem-solving .issueTables .card .card-content {
  padding-top: 0;
  display: flex;
}
</style>
