var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"trends"},[(!_vm.loadingTrends)?_c('div',{staticClass:"trends-grid"},_vm._l((_vm.trends),function(trend){return _c('div',{key:trend.node.name},[_c('div',{staticClass:"d-flex justify-space-between align-center mb-4"},[_c('h3',{staticClass:"trends-title color--text-primary text-subtitle-2 text-uppercase"},[_vm._v("\n          "+_vm._s(trend.node.name)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"d-flex align-center"},[(trend.node.name !== 'Safety')?_c('metric-input',{staticClass:"edit-btn-target",attrs:{"trend":trend,"machine_group_id":_vm.machine_group_id},on:{"metric_update":_vm.loadTrends}}):_vm._e(),_vm._v(" "),_c('Btn',{staticClass:"expand-btn ml-3 color--primary",attrs:{"icon":"","large":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.setEnlargedTrend(trend.node)}}},[_c('v-icon',[_vm._v("mdi-arrow-expand")])],1)],1)]),_vm._v(" "),_c('DynamicTable',{attrs:{"headers":[
          { text: '', value: 'name' },
          { text: _vm.$t('Target'), value: 'target', align: 'center' },
          { text: _vm.$t('Actual'), value: 'actual', align: 'center' }
        ],"items":[
          {
            name: 'Incidents',
            target: 159,
            actual: 6.0
          },
          {
            name: 'Open',
            target: 159,
            actual: 6.0
          },
          {
            name: 'Closed',
            target: 159,
            actual: 6.0
          }
        ],"dynamic-slots":['actual'],"hide-default-footer":true,"disable-pagination":true,"is-loading":_vm.loadingTrends},scopedSlots:_vm._u([{key:"item.actual",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"d-inline-block chip"},[_c('v-chip',{attrs:{"label":""}},[_vm._v(_vm._s(item.actual))])],1)]}}],null,true)})],1)}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }