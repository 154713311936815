<template>
  <div class="enlarged-trend">
    <div class="d-flex justify-space-between align-center mb-6">
      <div class="title">
        <h3 class="text-subtitle-1 text-uppercase mb-2">{{ enlargedTrend.name }}</h3>
        <div class="text-h6 font-weight-medium">{{ startDate }} - {{ endDate }}</div>
      </div>
      <div class="d-flex align-center">
        <div>
          <BtnToggle
            v-if="!isSafetyTrend"
            :value="scaleTab"
            class="inline-block"
          >
            <v-btn
              :value="3"
              @click="scaleTab = 3"
            >
              {{ $t("day") }}
            </v-btn>

            <v-btn
              :value="0"
              @click="scaleTab = 0"
            >
              {{ $t("week") }}
            </v-btn>

            <v-btn
              :value="1"
              @click="scaleTab = 1"
            >
              {{ $t("month") }}
            </v-btn>

            <v-btn
              :value="2"
              @click="scaleTab = 2"
            >
              {{ $t("year") }}
            </v-btn>
          </BtnToggle>

          <v-btn
            v-if="!isSafetyTrend"
            large
            icon
            @click="showDateRangePicker = true"
          >
            <v-icon color="primary">mdi-calendar-range-outline</v-icon>
          </v-btn>
        </div>

        <ZoneControlSafetyEvent
          v-if="isSafetyTrend"
          :selected-event="selectedSafetyEvent"
          @safetyEventSubmitted="incrementSafetyEventSubmits"
          @clear-selected-event="selectedSafetyEvent = null"
        />
        <Btn
          class="expand color--primary ml-6"
          icon
          large
          @click.stop.prevent="setEnlargedTrend({})"
        >
          <v-icon color="primary">mdi-arrow-collapse</v-icon>
        </Btn>
      </div>
      <v-dialog
        v-model="showDateRangePicker"
        max-width="800"
      >
        <v-card>
          <DialogCloseButton :on-click="() => (showDateRangePicker = false)" />
          <v-card-title class="pb-0">{{ $t("Custom Date Range") }}</v-card-title>
          <v-card-text>
            <v-col>
              <v-row>
                <v-tabs
                  v-model="customDateInterval"
                  class="d-flex justify-center align-center"
                >
                  <v-tab>{{ $t("day") }}</v-tab>
                  <v-tab>{{ $t("week") }}</v-tab>
                  <v-tab>{{ $t("month") }}</v-tab>
                  <v-tab>{{ $t("year") }}</v-tab>
                </v-tabs>
              </v-row>
              <v-row class="d-flex justify-center align-center">
                <v-date-picker
                  v-model="startDate"
                  no-title
                  scrollable
                  :locale="$i18n.locale()"
                />
                <v-date-picker
                  v-model="endDate"
                  :min="startDate"
                  no-title
                  scrollable
                  :locale="$i18n.locale()"
                />
              </v-row>
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="error"
              text
              @click="showDateRangePicker = false"
              >{{ $t("Cancel") }}</v-btn
            >
            <v-btn
              color="primary"
              text
              :disabled="!dateRangeValid"
              @click="applyDateRange"
              >{{ $t("Apply") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div
      class="card-content"
      :style="isSafetyTrend ? 'overflow: hidden' : ''"
    >
      <ZoneControlSafetyCard
        v-if="isSafetyTrend"
        :level="levelId"
        :machine_group_pk="machineGroupPk"
        :safety-event-submits="safetyEventSubmits"
        @editSafetyEvent="handleEditSafetyEvent"
        @editShift="handleEditShift"
      />
      <ZoneControlTrendCard
        v-else
        :trend="{ ...enlargedTrend, machine_group_id }"
        :time-scale="scaleTab"
        @editShift="handleEditShift"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";

import Btn from "@/ui/Btn";
import BtnToggle from "@/components/BtnToggle.vue";
import DialogCloseButton from "@/components/DialogCloseButton";
import ZoneControlSafetyCard from "./ZoneControlSafetyCard.vue";
import ZoneControlTrendCard from "./ZoneControlTrendCard.vue";

import ZoneControlSafetyEvent from "./ZoneControlSafetyEvent.vue";

export default {
  name: "ZoneControlEnlargedTrend",
  components: {
    Btn,
    BtnToggle,
    DialogCloseButton,
    ZoneControlSafetyEvent,
    ZoneControlSafetyCard,
    ZoneControlTrendCard
  },
  props: {
    enlargedTrend: { type: Object, default: () => {} },
    setEnlargedTrend: { type: Function, default: () => null },
    levelId: { type: Number, default: undefined },
    machine_group_id: { type: String, default: "" },
    machineGroupPk: { type: Number, default: undefined }
  },
  data() {
    return {
      scaleTab: 0,
      showDateRangePicker: false,
      customDateInterval: null,
      startDate: moment().startOf("week").format("DD MMM"),
      endDate: moment().endOf("week").format("DD MMM, YYYY"),
      dateRangeValid: false,
      selectedSafetyEvent: null,
      safetyEventSubmits: 0
    };
  },
  computed: {
    isSafetyTrend() {
      return this.enlargedTrend.name === "Safety";
    }
  },
  watch: {
    startDate() {
      this.determineDateRangeValid();
    },
    endDate() {
      this.determineDateRangeValid();
    }
  },
  methods: {
    applyDateRange() {
      const scales = ["day", "week", "month", "year"];
      this.scaleTab = `${moment(this.startDate).startOf("day").toISOString()} ${moment(this.endDate)
        .endOf("day")
        .toISOString()} ${scales[this.customDateInterval]}`;
      this.showDateRangePicker = false;
    },
    determineDateRangeValid() {
      // ensure that startDate and endDate are both set, and that startDate is before endDate
      if (this.startDate && this.endDate) {
        this.dateRangeValid = moment(this.startDate).isBefore(moment(this.endDate));
      } else {
        this.dateRangeValid = false;
      }
    },
    handleEditSafetyEvent(selectedEvent) {
      this.selectedSafetyEvent = selectedEvent;
    },
    incrementSafetyEventSubmits() {
      this.safetyEventSubmits++;
    },
    handleEditShift(from, to) {
      this.startDate = from.format("MMM DD");
      this.endDate = to.format("MMM DD, Y");
    }
  }
};
</script>
