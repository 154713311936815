<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <transition name="slide-fade">
    <div class="oee-chart metric-component">
      <h2
        class="text-subtitle-2 color--text-primary font-weight-medium text-uppercase d-block mb-3"
      >
        {{ $t("OEE") }}
      </h2>

      <v-progress-linear
        v-if="!(production && production.length > 0)"
        indeterminate
      ></v-progress-linear>

      <div
        v-else
        class="charts"
      >
        <div class="mb-5 text-h5 color--text-primary font-weight-medium">
          {{ Math.round(oee) }}{{ "%".trim() }}
        </div>
        <div class="charts_container">
          <div class="chart">
            <line-chart
              v-if="chartData"
              chart-name="oeeChart"
              class="dynamicChart"
              uom="%"
              :decimal="2"
              :chart-data="chartData"
              :chart-options="chartOptions"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import moment from "moment";

import LineChart from "../charts/LineChart.js";

export default {
  components: {
    LineChart
  },
  props: ["options", "production"],
  data() {
    return {
      chartData: false,
      oee: false
    };
  },
  computed: {
    chartOptions() {
      let defaults = {
        scales: {
          scaleLineColor: "transparent",
          yAxes: [
            {
              display: false,
              ticks: {
                suggestedMin: 0,
                suggestedMax: 100,
                padding: 8
              }
            }
          ],
          xAxes: [
            {
              offset: true,
              ticks: {
                padding: 8
              }
            }
          ]
        }
      };
      if (this.options.settings) {
        return _.merge(defaults, this.options.settings);
      }
      return defaults;
    }
  },
  watch: {
    production: {
      handler: "format_data",
      deep: true
    }
  },
  created() {
    if (this.production && this.production.length > 0) {
      this.format_data();
    }
  },
  methods: {
    moment,
    format_data() {
      let percentage = [];
      let labels = [];
      let titles = [];
      let footers = [];
      let total_produced = 0;
      let total_production_target = 0;
      let total_scrapped = 0;
      let total_runtime = 0;
      let total_time = 0;
      let total_planned = 0;
      if (this.production) {
        this.production.forEach((interval) => {
          let produced = interval.produced.value;
          let production_target = interval.production_target.value;
          let scrapped = interval.scrapped.value;
          let runtime = interval.runtime.duration.value;
          let planned = interval.planned.duration.value;
          let duration = interval.duration.value;
          let perf = (produced / production_target) * 100;
          let quality = ((produced - scrapped) / produced) * 100;
          // let runtime_per = (runtime / duration) * 100;
          let availability = (runtime / (duration - planned)) * 100;
          let oee = interval.oee ? interval.oee.value * 100 : null;
          percentage.push(oee);
          footers.push([
            this.$t("Performance") + " : " + perf.toFixed(2) + "%",
            this.$t("Quality") + " : " + quality.toFixed(2) + "%",
            this.$t("Availability") + " : " + availability.toFixed(2) + "%"
          ]);
          labels.push(this.options.labelFromString(interval.key_as_string));
          titles.push(this.options.labelFromToString(interval.key_as_string));

          total_produced += produced;
          total_production_target += production_target;
          total_scrapped += scrapped;
          total_runtime += runtime;
          total_planned += planned;
          total_time += duration;
        });
        this.oee =
          (((((total_produced / total_production_target) * (total_produced - total_scrapped)) /
            total_produced) *
            total_runtime) /
            (total_time - total_planned)) *
          100;
        if (this.oee < 0) {
          this.oee = 0;
        }
      }
      this.chartData = {
        threshold: this.options.targets.oee,
        datasets: [
          {
            data: percentage,
            titles: titles,
            footers: footers,
            label: this.$t("OEE"),
            fill: false,
            pointRadius: 3
          }
        ],
        labels: labels
      };
    }
  }
};
</script>

<style scoped lang="scss">
.oee-chart {
  padding: 0;
  min-height: 18vh;
  flex: 1;
  justify-self: normal;
  align-self: normal;

  .charts {
    display: block;
  }

  .charts_container {
    position: static;
    padding: 0;
    display: block;

    .chart {
      height: 250px;
      width: 100%;
      margin: 0;

      .dynamicChart {
        height: 250px;
        width: 100%;
      }
    }
  }
}
</style>
