<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <transition name="slide-fade">
    <div class="production-chart metric-component">
      <h2 class="text-subtitle-2 color--text-primary font-weight-medium text-uppercase">
        {{ $t("Performance") }}
      </h2>

      <v-progress-linear
        v-if="!(production && production.length > 0)"
        indeterminate
      ></v-progress-linear>

      <div
        v-else
        class="charts"
      >
        <div class="mb-5 text-h5 color--text-primary font-weight-medium">
          {{ Match.round(perf) }}
        </div>

        <div class="charts_container">
          <div class="chart">
            <bar-chart
              v-if="chartData"
              chart-name="productionChart"
              class="productionChart"
              uom="%"
              :decimal="2"
              :chart-data="chartData"
              :chart-options="chartOptions"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

import BarChart from "../charts/BarChart.js";

export default {
  components: {
    BarChart
  },
  props: ["options", "production"],
  data() {
    return {
      chartData: false,
      perf: false
    };
  },
  computed: {
    ...mapGetters({
      theme: "app/Theme"
    }),
    chartOptions() {
      let defaults = {
        scales: {
          scaleLineColor: "transparent",
          yAxes: [{ display: false }]
        }
      };
      if (this.options.settings) {
        return _.merge(defaults, this.options.settings);
      }
      return defaults;
    }
  },
  watch: {
    production: {
      deep: true,
      handler: "format_data"
    }
  },
  created() {
    if (this.production && this.production.length > 0) {
      this.format_data();
    }
  },
  methods: {
    moment,
    format_data() {
      let percentage = [];
      let labels = [];
      let titles = [];
      let footers = [];
      let gradients = [];
      let total = 0;
      let target_total = 0;
      let targets = [];
      if (this.production) {
        this.production.forEach((interval) => {
          let produced = interval.produced.value;
          let production_target = interval.production_target.value;
          let performance_percentage = interval.performance ? interval.performance.value * 100 : 0;
          percentage.push(performance_percentage);
          targets.push(this.options.targets.perf);
          if (performance_percentage >= this.options.targets.perf) {
            gradients.push(this.$colors.chart.good_gradient);
          } else {
            gradients.push(this.$colors.chart.bad_gradient);
          }
          footers.push([
            this.$t("Produced") + " : " + produced,
            this.$t("Target") + " : " + Math.round(production_target)
          ]);
          labels.push(this.options.labelFromString(interval.key_as_string));
          titles.push(this.options.labelFromToString(interval.key_as_string));

          total += produced;
          target_total += production_target;
        });
        this.perf = (total / target_total) * 100;
      }
      this.chartData = {
        datasets: [
          {
            type: "line",
            data: targets,
            borderWidth: 2,
            pointRadius: 0,
            hoverPointRadius: 0,
            borderColor: this.theme == "dark" ? "white" : "#44B2F3",
            titles: titles,
            fill: false,
            label: this.$t("Target")
          },
          {
            data: percentage,
            gradients: gradients,
            footers: footers,
            titles: titles,
            label: this.$t("Performance")
          }
        ],
        labels: labels
      };
    }
  }
};
</script>

<style scoped lang="scss">
.production-chart {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  min-height: 18vh;

  .charts {
    display: block;

    .charts_container {
      width: 100%;

      .productionChart {
        width: 100%;
        height: 250px;
        padding-left: 0;
      }
    }
  }
}
</style>
