import { AppRoutes } from "@/shared/app-routes";
import Vue from "vue";

export default [
  {
    name: AppRoutes.irisGlobalSettingsBase.name,
    path: AppRoutes.irisGlobalSettingsBase.url,
    component: Vue.component(
      AppRoutes.irisGlobalSettingsBase.name,
      () => import("./IrisGlobalSettingsBase.vue")
    ),
    props: false,
    displayName: false,
    meta: AppRoutes.irisGlobalSettingsBase.meta,
    redirect: { name: AppRoutes.irisGlobalSettingsAssistants.name },
    children: [
      {
        name: AppRoutes.irisGlobalSettingsSidebarLayout.name,
        path: AppRoutes.irisGlobalSettingsSidebarLayout.url,
        component: Vue.component(
          AppRoutes.irisGlobalSettingsSidebarLayout.name,
          () => import("./IrisGlobalSettingsSidebarLayout.vue")
        ),
        props: false,
        displayName: false,
        meta: AppRoutes.irisGlobalSettingsSidebarLayout.meta,
        children: [
          {
            name: AppRoutes.irisGlobalSettingsAssistants.name,
            path: AppRoutes.irisGlobalSettingsAssistants.url,
            component: Vue.component(
              AppRoutes.irisGlobalSettingsAssistants.name,
              () => import("./components/assistants/IrisGlobalSettingsAssistantsList.vue")
            ),
            props: false,
            displayName: false,
            meta: AppRoutes.irisGlobalSettingsAssistants.meta
          },
          {
            name: AppRoutes.irisGlobalSettingsLlmReports.name,
            path: AppRoutes.irisGlobalSettingsLlmReports.url,
            component: Vue.component(
              AppRoutes.irisGlobalSettingsLlmReports.name,
              () => import("./components/llm-reports/IrisGlobalSettingsLlmReports.vue")
            ),
            props: false,
            displayName: false,
            meta: AppRoutes.irisGlobalSettingsLlmReports.meta
          }
        ]
      }
    ]
  }
];
