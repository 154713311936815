<template>
  <v-app>
    <div
      class="home"
      :class="{
        openNav: nav == 'open',
        openHelpPanel: showHelp,
        openDocPanel: rightPanel == 'docs' || rightPanel == 'video',
        openAuditsPanel: rightPanel == 'audits',
        openSearchPanel: rightPanel == 'search'
      }"
    >
      <banner />
      <nav-bar
        ref="navbar"
        :status="nav"
      ></nav-bar>
      <div
        class="content"
        v-if="isFetchedTimezone"
      >
        <transition
          name="fade-in-up"
          mode="out-in"
        >
          <router-view transition-mode="out-in"></router-view>
        </transition>
      </div>
      <audits></audits>
      <help></help>
      <doc-center></doc-center>
      <search></search>
    </div>
    <div class="alert-container">
      <v-alert
        v-for="(alert, idx) in alerts"
        :key="idx"
        :type="alert.type"
        elevation="2"
        transition="scale-transition"
        dense
        >{{ alert.text }}</v-alert
      >
    </div>
    <div class="csv-tracker-container">
      <v-alert
        v-if="csvTracker.show"
        :color="csvTracker.color"
        elevation="2"
        transition="scale-transition"
        dense
      >
        <template v-slot:default>
          <p v-if="csvTracker.name">{{ csvTracker.name }}</p>
          <p v-if="csvTracker.error">{{ csvTracker.error }}</p>
          <p v-else-if="csvTracker.status">
            {{ csvTracker.status.toUpperCase() }}
            <span v-if="csvTracker.progress != -1">{{ csvTracker.progress }}%</span>
          </p>
          <v-progress-linear
            v-if="!csvTracker.error && !csvTracker.complete"
            :value="csvTracker.progress"
            :indeterminate="csvTracker.progress == -1"
            color="white"
          ></v-progress-linear>
        </template>
      </v-alert>
    </div>

    <portal-target name="side-drawer" />
  </v-app>
</template>

<style lang="scss">
@import "../scss/variables";
@import "../scss/mq";

body {
  // background-image: url("../assets/background.jpg");
  background-color: #2d2b35;

  .home {
    z-index: 1;

    nav {
      transition: all 0.2s ease-in-out;
      position: fixed;
      top: $bannerHeight;
      left: -$navShiftDistance;
      bottom: 0;
    }

    .content {
      position: fixed;
      top: $bannerHeight;
      bottom: 0;
      right: 0;
      left: 0;
      padding: 0;
      overflow: auto;
      transition: all 0.2s ease-in-out;

      @media screen and (max-width: 700px) {
        top: $bannerHeightMobile;
        margin-top: $bodyMobileTopPadding;
      }

      &::after {
        content: "";
        box-shadow: inset 10px 0 10px #2d2b35;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 100%;
      }
    }
    .help,
    .doc-center,
    .audits-center,
    .search-panel {
      transition: all 0.2s ease-in-out;
      position: fixed;
      top: $bannerHeight;
      right: -$rightPanelShiftDistance;
      bottom: 0;
    }
    &.openNav {
      nav {
        left: 1rem;
      }
      .content {
        left: $navShiftDistance;
        right: -$navShiftDistance;
      }
    }
    &.openDocPanel {
      .content {
        left: -$rightPanelShiftDistance;
        right: $rightPanelShiftDistance;
      }
      .doc-center {
        right: 1rem;
      }
    }

    &.openAuditsPanel {
      .content {
        left: -$rightPanelShiftDistance;
        right: $rightPanelShiftDistance;
      }
      .audits-center {
        right: 1rem;
      }
    }
    &.openHelpPanel {
      .content {
        left: -$rightPanelShiftDistance;
        right: $rightPanelShiftDistance;
      }
      .help {
        right: 1rem;
      }
    }
    &.openSearchPanel {
      .content {
        left: -$rightPanelShiftDistance;
        right: $rightPanelShiftDistance;
      }
      .search-panel {
        right: 1rem;
      }
    }
  }
}
.alert-container,
.csv-tracker-container {
  position: fixed;
  bottom: 0;
  left: 15px;
  width: 300px;
  z-index: 999999;
}
.theme--light.v-application {
  .home {
    .content {
      &::after {
        box-shadow: none;
      }
    }
  }
}
@include media_below(450px) {
  body {
    .home {
      .help,
      .doc-center,
      .audits-center,
      .search-panel {
        // right: calc(100vw - 1rem);
        width: calc(100vw - 1.5rem);
      }
      &.openNav {
        nav {
          left: 0.75rem;
        }
      }
      &.openDocPanel {
        .doc-center {
          right: 0.75rem;
        }
      }

      &.openAuditsPanel {
        .audits-center {
          right: 0.75rem;
        }
      }
      &.openHelpPanel {
        .help {
          right: 0.75rem;
        }
      }
      &.openSearchPanel {
        .search-panel {
          right: 0.75rem;
        }
      }
    }
  }
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";

import Banner from "@/components/Banner";
import NavBar from "@/components/NavBar";
import Audits from "@/components/right_panels/Audits";
import DocCenter from "@/components/right_panels/DocCenter";
import Help from "@/components/right_panels/Help";
import Search from "@/components/right_panels/Search";
import { Dispatcher } from "@/utils/eventbus";
import { useSettingsTimezoneQuery } from "../common/settings/useSettingsTimezoneQuery";
import { changeLocale } from "../utils/language";
import { hasUserRole, userRoles } from "../utils/user";

let inactiveHandler = null;
function onInactive(millisecond, callback, reset) {
  var wait = setTimeout(callback, millisecond);
  inactiveHandler = function () {
    clearTimeout(wait);
    reset();
    wait = setTimeout(callback, millisecond);
  };
  ["mousemove", "mousedown", "mouseup", "keydown", "keyup", "focus"].forEach(
    function (event, index) {
      document.addEventListener(event, inactiveHandler);
    }
  );
}

// import Subnav from '../components/Subnav';
// import Loading from '@/components/Loading';

class Alert {
  constructor(text, type = "info", timeout = 3000) {
    this.text = text;
    this.type = type;
    this.timeout = timeout;
  }
}

export default {
  components: {
    NavBar,
    Audits,
    DocCenter,
    Banner,
    Help,
    Search
  },
  data() {
    return {
      nav: "closed",
      userSocket: null,
      verifyInterval: null,
      alerts: [],
      csvTracker: {
        name: "",
        color: "info",
        status: null,
        progress: -1,
        show: false,
        error: null,
        complete: null
      }
    };
  },
  beforeDestroy() {
    Dispatcher.$silence("NAVIGATION");
    Dispatcher.$silence("ALERT", this.alert);
    if (this.userSocket) {
      this.userSocket.close();
      this.userSocket = null;
    }
    if (this.verifyInterval) {
      clearInterval(this.verifyInterval);
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "session/IsAuthenticated",
      expire: "session/AuthExpiration",
      showHelp: "components/HelpCenterVisible",
      rightPanel: "components/RightPanel",
      user: "session/User"
    })
  },
  setup() {
    const { timezone, isFetchedTimezone } = useSettingsTimezoneQuery();

    return {
      timezone,
      isFetchedTimezone
    };
  },
  mounted() {
    this.loadAppData();
    this.connectUserWebsocket();
    Dispatcher.$listen("NAVIGATION", this.setNav);
    Dispatcher.$listen("ALERT", this.alert);
    Dispatcher.$listen("CSV", this.csv);
    if (this.user) {
      if (this.user.theme == 1) {
        this.setTheme("dark");
        this.$vuetify.theme.dark = true;
      } else {
        this.setTheme("light");
        this.$vuetify.theme.dark = false;
      }
    }
    let inactive = false;
    const checkAuth = () => {
      const now = Math.floor(Date.now() / 1000);
      if (now > this.expire) {
        return false;
      }
      // 5 min give for refresh
      if (now > this.expire - 300) {
        if (!inactive) {
          this.refresh();
        }
      }
      return true;
    };
    if (!checkAuth()) {
      this.logout();
    } else {
      this.verifyInterval = setInterval(() => {
        if (!checkAuth()) {
          this.logout();
        }
      }, 10000);
      if (!hasUserRole(this.user, userRoles.Kiosk)) {
        onInactive(900000, this.logout, function () {
          inactive = false;
        });
      }
    }
  },
  watch: {
    $route() {
      if (this.$refs.navbar) {
        this.$refs.navbar.close();
      }
      if (this.$refs.subnav) {
        this.$refs.subnav.close();
      }
    },
    user() {
      this.connectUserWebsocket();
    }
  },
  methods: {
    ...mapActions({
      refresh: "session/Refresh",
      setTimezone: "app/SetTimezone",
      setDisableRoutes: "routes/SetDisabledRoutes",
      setHelpCenterVisibility: "components/SetHelpCenterVisibility",
      setStatuses: "app/SetStatuses",
      setTheme: "app/SetTheme",
      initializeDbCache: "dbCache/initializeCache",
      logout: "session/Logout"
    }),
    async loadAppData() {
      await this.initializeDbCache();
      const query = `query($languageId:Int){
          language(id:$languageId){
            id,
            name,
            abv,
            translations
          },
          statuses{
            id,
            pk,
            name,
            order,
            running,
            plannedDown,
            colorSet,
            icon,
            statuscodeSet{
              id,
              pk,
              code,
              description,
              machines{
                id
                pk
              }
            }
          }
        }`;
      const variables = {
        languageId: this.user.language
      };
      this.$http.post("graphql/", { query, variables }).then((res) => {
        res.data.data.statuses.sort((a, b) => a.order - b.order);
        this.setStatuses(res.data.data.statuses);
        changeLocale(res.data.data.language);
      });
    },
    setNav(status) {
      if (status == "toggle") {
        if (this.nav == "open") {
          this.nav = "closed";
        } else {
          this.setHelpCenterVisibility({ visible: false });
          this.nav = "open";
        }
      } else {
        if (status == "open") {
          this.setHelpCenterVisibility({ visible: false });
        }
        this.nav = status;
      }
    },
    alert({ text, type = "info", timeout = 3000 }) {
      let alert = new Alert(text, type, timeout);
      this.alerts.push(alert);
      setTimeout(() => {
        this.alerts.pop();
      }, timeout);
    },
    csv({ name = false, status = false, progress = -1, error = false, complete = false }) {
      this.csvTracker.show = true;
      this.csvTracker.progress = progress;
      if (name) {
        this.csvTracker.name = name;
      }
      if (status) {
        this.csvTracker.status = status;
      }
      if (complete) {
        this.csvTracker.complete = true;
        this.csvTracker.color = "success";
        this.csvTracker.status = this.$t("COMPLETE");
        let csvTracker = this.csvTracker;
        setTimeout(() => {
          csvTracker.show = false;
          csvTracker.name = false;
          csvTracker.progress = -1;
          csvTracker.status = null;
          csvTracker.error = null;
          csvTracker.complete = false;
          csvTracker.color = "info";
        }, 5000);
      }
      if (error) {
        this.csvTracker.error = error;
        this.csvTracker.color = "error";
        this.csvTracker.status = this.$t("FAILED");
        let csvTracker = this.csvTracker;
        setTimeout(() => {
          csvTracker.show = false;
          csvTracker.name = false;
          csvTracker.progress = -1;
          csvTracker.status = null;
          csvTracker.error = null;
          csvTracker.complete = false;
          csvTracker.color = "info";
        }, 5000);
      }
    },
    connectUserWebsocket() {
      if (this.user) {
        this.userSocket = this.$socket(`user/${this.user.id}/`, (e) => {
          let message = JSON.parse(e.data);
          let type = message.type;
          let body = message.body;
        });
      }
    }
  }
};
</script>
