<template>
  <div class="trends">
    <div
      v-if="!loadingTrends"
      class="trends-grid"
    >
      <div
        v-for="trend in trends"
        :key="trend.node.name"
      >
        <div class="d-flex justify-space-between align-center mb-4">
          <h3 class="trends-title color--text-primary text-subtitle-2 text-uppercase">
            {{ trend.node.name }}
          </h3>

          <div class="d-flex align-center">
            <metric-input
              v-if="trend.node.name !== 'Safety'"
              :trend="trend"
              :machine_group_id="machine_group_id"
              class="edit-btn-target"
              @metric_update="loadTrends"
            />

            <Btn
              class="expand-btn ml-3 color--primary"
              icon
              large
              @click.stop.prevent="setEnlargedTrend(trend.node)"
            >
              <v-icon>mdi-arrow-expand</v-icon>
            </Btn>
          </div>
        </div>

        <DynamicTable
          :headers="[
            { text: '', value: 'name' },
            { text: $t('Target'), value: 'target', align: 'center' },
            { text: $t('Actual'), value: 'actual', align: 'center' }
          ]"
          :items="[
            {
              name: 'Incidents',
              target: 159,
              actual: 6.0
            },
            {
              name: 'Open',
              target: 159,
              actual: 6.0
            },
            {
              name: 'Closed',
              target: 159,
              actual: 6.0
            }
          ]"
          :dynamic-slots="['actual']"
          :hide-default-footer="true"
          :disable-pagination="true"
          :is-loading="loadingTrends"
        >
          <template #[`item.actual`]="{ item }">
            <span class="d-inline-block chip">
              <v-chip label>{{ item.actual }}</v-chip>
            </span>
          </template>
        </DynamicTable>
      </div>
    </div>

    <!-- <div
      v-if="!loadingTrends"
      class="trends-card-overflow"
    >
      <div
        v-for="trend in trends"
        :key="trend.node.name"
        class="card"
      >
        <div class="card-header">
          <h3 @click.stop.prevent="setEnlargedTrend(trend.node)">{{ $t(trend.node.name) }}</h3>
          <v-btn
            text
            small
            @click.stop.prevent="setEnlargedTrend(trend.node)"
          >
            {{ $t(trend.node.name) }}
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <div class="headerBtns">
            <metric-input
              v-if="trend.node.name !== 'Safety'"
              :trend="trend"
              :machine_group_id="machine_group_id"
              @metric_update="loadTrends"
            />
          </div>
        </div>

        <div class="card-content">
          <div class="metrics">
            <div class="metric">
              <div class="metric-value">
                <p class="name"></p>
                <p class="target label">{{ $t("Target") }}</p>
                <p class="actual label">{{ $t("Actual") }}</p>
              </div>
            </div>
            <div
              v-for="(metric, index) in trend.node.metrics.edges"
              :key="index"
              class="metric"
            >
              <div class="metric-value">
                <p class="name">{{ $t(metric.node.name) }}</p>
                <p class="target">{{ metric.node.target ? metric.node.target : "" }}</p>
                <p class="actual">
                  {{ metric.node.currentValue ? metric.node.currentValue.value : "" }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import moment from "moment";

import Btn from "@/ui/Btn";
import DynamicTable from "@/ui/DynamicTable";
import MetricInput from "./ZoneControlMetricInput.vue";
import { numberWithCommas } from "@/utils/filters";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "ZoneControlTrends",
  components: {
    Btn,
    DynamicTable,
    MetricInput
  },
  props: {
    machine_group_id: { type: String, default: "" },
    machineGroupPk: { type: Number, default: undefined },
    levelId: { type: Number, default: undefined },
    enlargedTrend: { type: Object, default: () => {} },
    setEnlargedTrend: { type: Function, default: () => null }
  },
  data() {
    return {
      loadingTrends: false,
      safetyGroupLevel: null,
      groupsBySafetyLevel: [],
      trends: [
        {
          node: {
            name: this.$t("Safety"),
            metrics: {
              edges: [
                { node: { name: this.$t("Number of Incidents"), value: 3 } },
                { node: { name: this.$t("Open Incidents"), value: 1 } },
                { node: { name: this.$t("Closed Incidents"), value: 2 } }
              ]
            }
          }
        },
        {
          node: {
            name: this.$t("Quality"),
            metrics: {
              edges: [
                { node: { name: this.$t("First Time Quality"), value: "98%" } },
                { node: { name: this.$t("Opportunity Cost"), value: "$2,835" } }
              ]
            }
          }
        },
        {
          node: {
            name: this.$t("Delivery"),
            metrics: {
              edges: [{ node: { name: this.$t("Schedule Attainment"), value: "95%" } }]
            }
          }
        },
        {
          node: {
            name: this.$t("Cost"),
            metrics: {
              edges: [
                { node: { name: this.$t("OEE"), value: "88%" } },
                { node: { name: this.$t("Opportunity Cost"), value: "$12,835" } },
                { node: { name: this.$t("Labor Efficiency"), value: "78%" } }
              ]
            }
          }
        }
      ]
    };
  },
  async created() {
    await this.setGroupsBySafetyLevel();
    this.loadTrends();
    if (window.location.href.includes("safety")) {
      const safetyTrend = this.trends.find((trend) => trend.node.name === this.$t("Safety"));
      if (safetyTrend) {
        this.setEnlargedTrend(safetyTrend.node);
      }
    }
  },
  methods: {
    loadSafetyMetrics(metric, machine_group_ids) {
      const params = {
        metric: metric.node["automatic"].toLowerCase(),
        group_ids: machine_group_ids
      };
      this.$http.get(`safety/safety_metric/`, { params }).then((res) => {
        metric.node.currentValue = {
          value: res.data.toLocaleString()
        };
      });
    },
    async getSafetyGroupLevel() {
      const response = await this.$http.get("graphql/", {
        params: {
          query: `{
           settings {
             safetyGroupLevel {
               pk
               name
               id
               level
             }
           }
         }`
        }
      });
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.settings &&
        response.data.data.settings.length > 0 &&
        response.data.data.settings[0].safetyGroupLevel &&
        response.data.data.settings[0].safetyGroupLevel.level
      ) {
        this.safetyGroupLevel = response.data.data.settings[0].safetyGroupLevel.level;
      } else {
        this.safetyGroupLevel = null;
      }
    },
    async setGroupsBySafetyLevel() {
      await this.getSafetyGroupLevel();
      if (this.levelId === this.safetyGroupLevel) {
        this.groupsBySafetyLevel.push(this.machineGroupPk);
      } else if (this.levelId > this.safetyGroupLevel) {
        // get all subgroup ids at the safety group level
        this.$http
          .get("graphql/", {
            params: {
              query: `{
              machineGroups {
                pk
                level {
                  level
                }
                allSubGroups {
                  pk
                  level {
                    level
                  }
                }
              }
            }`
            }
          })
          .then((res) => {
            const machineGroups = res.data.data.machineGroups;
            const machineGroup = machineGroups.find(
              (machineGroup) => machineGroup.pk == this.machineGroupPk
            );
            const subGroups = machineGroup["allSubGroups"];
            subGroups.forEach((subGroup) => {
              if (subGroup.level.level === this.safetyGroupLevel) {
                this.groupsBySafetyLevel.push(subGroup.pk);
              }
            });
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        // find the parent at the safety group level
        this.$http
          .get("graphql/", {
            params: {
              query: `{
              machineGroups {
                pk
                level {
                  level
                }
                allSubGroups {
                  pk
                  level {
                    level
                  }
                }
              }
            }`
            }
          })
          .then((res) => {
            const machineGroups = res.data.data.machineGroups;
            const machineGroupsAtThisLevel = machineGroups.filter((machineGroup) => {
              return machineGroup.level.level === this.safetyGroupLevel;
            });
            machineGroupsAtThisLevel.forEach((machineGroup) => {
              const subGroups = machineGroup["allSubGroups"];
              subGroups.forEach((subGroup) => {
                if (subGroup.pk === this.machineGroupPk) {
                  this.groupsBySafetyLevel.push(machineGroup.pk);
                }
              });
            });
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    loadTrends() {
      this.loadingTrends = true;
      let query = `query($machineGroupId: ID!) {
        plantTrends {
          edges {
            node {
              name
              order
              metrics {
                edges {
                  node {
                    id
                    name
                    order
                    target
                    automatic
                    autoMetricHours
                    currentValue(machineGroup: $machineGroupId) {
                      value
                    }
                  }
                }
              }
            }
          }
        }
      }`;
      let variables = { machineGroupId: this.machine_group_id };
      this.$http.post("graphql/", { query, variables }).then((res) => {
        this.trends = res.data.data.plantTrends.edges.sort((a, b) => a.node.order - b.node.order);
        this.trends.forEach((trend) => {
          const numberOfMetrics = trend.node.metrics.edges.length;
          let numberOfAutomaticMetrics = 0;
          trend.node.metrics.edges.forEach((metric) => {
            if (metric.node.automatic) {
              numberOfAutomaticMetrics++;
              let params = {
                machine_group_id: this.machineGroupPk,
                from_date: moment()
                  .startOf("hour")
                  .subtract(metric.node.autoMetricHours, "hours")
                  .toISOString(),
                to_date: moment().startOf("hour").toISOString()
              };
              if (metric.node.automatic.includes("COST")) {
                this.$http.get("variance/summary/", { params }).then((res) => {
                  switch (metric.node.automatic) {
                    case "PERFORMANCE_OPPORTUNITY_COST":
                      metric.node.currentValue = {
                        value:
                          "$" +
                          numberWithCommas(Math.round(res.data.total_performance_variance.value))
                      };
                      break;
                    case "QUALITY_OPPORTUNITY_COST":
                      metric.node.currentValue = {
                        value:
                          "$" + numberWithCommas(Math.round(res.data.total_quality_variance.value))
                      };
                      break;
                    case "OEE_OPPORTUNITY_COST":
                      metric.node.currentValue = {
                        value:
                          "$" +
                          numberWithCommas(
                            Math.round(
                              res.data.total_performance_variance.value +
                                res.data.total_quality_variance.value +
                                res.data.total_availability_variance.value
                            )
                          )
                      };
                      // eslint-disable-next-line prettier/prettier
                      break;
                    case "SCRAP_COST":
                      metric.node.currentValue = {
                        value: "$" + numberWithCommas(Math.round(res.data.total_scrap_cost.value))
                      };
                      break;
                  }
                });
              } else if (metric.node["automatic"].includes("SAFETY")) {
                this.setGroupsBySafetyLevel()
                  .then(() => this.loadSafetyMetrics(metric, this.groupsBySafetyLevel))
                  .catch((err) => {
                    console.error(err);
                  });
              } else {
                this.$http.get("metrics/machine_group_summary/", { params }).then((res) => {
                  switch (metric.node.automatic) {
                    case "PERFORMANCE":
                      if (res.data.production_target.value == 0) {
                        metric.node.currentValue = { value: "0%" };
                      } else {
                        metric.node.currentValue = {
                          value:
                            Math.round(
                              (res.data.produced.value / res.data.production_target.value) * 100
                            ) + "%"
                        };
                      }
                      break;
                    case "QUALITY":
                      if (res.data.produced.value == 0) {
                        metric.node.currentValue = { value: "0%" };
                      } else {
                        metric.node.currentValue = {
                          value:
                            Math.round(
                              ((res.data.produced.value - res.data.scrapped.value) /
                                res.data.produced.value) *
                                100
                            ) + "%"
                        };
                      }
                      break;
                    case "OEE":
                      if (
                        res.data.production_target.value == 0 ||
                        res.data.produced.value == 0 ||
                        res.data.duration.value == 0
                      ) {
                        metric.node.currentValue = { value: "0%" };
                      } else {
                        metric.node.currentValue = {
                          value:
                            Math.round(
                              (((((res.data.produced.value / res.data.production_target.value) *
                                (res.data.produced.value - res.data.scrapped.value)) /
                                res.data.produced.value) *
                                res.data.runtime.duration.value) /
                                (res.data.duration.value - res.data.planned.duration.value)) *
                                100
                            ) + "%"
                        };
                      }
                      break;
                  }
                });
              }
            }
          });
          if (numberOfAutomaticMetrics == numberOfMetrics) {
            trend.isAllAutomatic = true;
          }
        });
        this.loadingTrends = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.trends-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
}

.trends-title {
  ::v-deep {
    .v-btn__content {
      font-weight: 500;
      color: var(--text-primary);
      opacity: 1 !important;
    }
  }
}

.edit-btn {
  padding-left: 14px !important;
}

.expand-btn {
  background-color: var(--btn-secondary) !important;
}

.chip {
  ::v-deep .v-chip {
    padding-left: 20px;
    padding-right: 20px;
    color: var(--text-green);
    background-color: var(--bg-green);
  }
}
</style>
