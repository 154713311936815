<template>
  <v-col>
    <v-row class="d-flex align-center">
      <v-col width="auto">
        <h3 class="text-subtitle-1 color--secondary mb-3 text-center text-uppercase">
          {{ $t("Front") }}
        </h3>
        <svg viewBox="0 0 75 100">
          <image
            class="human-diagram"
            xlink:href="../../../assets/img/stickFigure.svg"
            height="100%"
            width="100%"
          />
          <g
            v-if="type === 'event' || (safetyTickets && safetyTickets.length)"
            class="injury-points-front"
          >
            <InjuryPoint
              :cx="50"
              :cy="3.5"
              :type="type"
              :affected-areas="affectedAreas"
              :safety-tickets="safetyTickets"
              point-name="headFront"
            />
            <InjuryPoint
              :cx="45"
              :cy="10"
              :type="type"
              :affected-areas="affectedAreas"
              :safety-tickets="safetyTickets"
              point-name="rightEye"
            />
            <InjuryPoint
              :cx="55"
              :cy="10"
              :type="type"
              :affected-areas="affectedAreas"
              :safety-tickets="safetyTickets"
              point-name="leftEye"
            />
            <InjuryPoint
              :cx="50"
              :cy="20"
              :type="type"
              :affected-areas="affectedAreas"
              :safety-tickets="safetyTickets"
              point-name="neckFront"
            />
            <InjuryPoint
              :cx="37"
              :cy="24"
              :type="type"
              :affected-areas="affectedAreas"
              :safety-tickets="safetyTickets"
              point-name="rightShoulder"
            />
            <InjuryPoint
              :cx="32"
              :cy="45"
              :type="type"
              :affected-areas="affectedAreas"
              :safety-tickets="safetyTickets"
              point-name="rightArm"
            />
            <InjuryPoint
              :cx="32"
              :cy="60"
              :type="type"
              :affected-areas="affectedAreas"
              :safety-tickets="safetyTickets"
              point-name="rightHand"
            />
            <InjuryPoint
              :cx="63"
              :cy="24"
              :type="type"
              :affected-areas="affectedAreas"
              :safety-tickets="safetyTickets"
              point-name="leftShoulder"
            />
            <InjuryPoint
              :cx="68"
              :cy="45"
              :type="type"
              :affected-areas="affectedAreas"
              :safety-tickets="safetyTickets"
              point-name="leftArm"
            />
            <InjuryPoint
              :cx="68"
              :cy="60"
              :type="type"
              :affected-areas="affectedAreas"
              :safety-tickets="safetyTickets"
              point-name="leftHand"
            />
            <InjuryPoint
              :cx="50"
              :cy="45"
              :type="type"
              :affected-areas="affectedAreas"
              :safety-tickets="safetyTickets"
              point-name="torso"
            />
            <InjuryPoint
              :cx="43.5"
              :cy="62"
              :type="type"
              :affected-areas="affectedAreas"
              :safety-tickets="safetyTickets"
              point-name="rightHip"
            />
            <InjuryPoint
              :cx="42.5"
              :cy="80"
              :type="type"
              :affected-areas="affectedAreas"
              :safety-tickets="safetyTickets"
              point-name="rightLeg"
            />
            <InjuryPoint
              :cx="41.5"
              :cy="95"
              :type="type"
              :affected-areas="affectedAreas"
              :safety-tickets="safetyTickets"
              point-name="rightFoot"
            />
            <InjuryPoint
              :cx="56.5"
              :cy="62"
              :type="type"
              :affected-areas="affectedAreas"
              :safety-tickets="safetyTickets"
              point-name="leftHip"
            />
            <InjuryPoint
              :cx="57.5"
              :cy="80"
              :type="type"
              :affected-areas="affectedAreas"
              :safety-tickets="safetyTickets"
              point-name="leftLeg"
            />
            <InjuryPoint
              :cx="58.5"
              :cy="95"
              :type="type"
              :affected-areas="affectedAreas"
              :safety-tickets="safetyTickets"
              point-name="leftFoot"
            />
          </g>
        </svg>
      </v-col>
      <v-col width="auto">
        <h3 class="text-subtitle-1 color--secondary mb-3 text-center text-uppercase">
          {{ $t("Back") }}
        </h3>
        <svg viewBox="0 0 75 100">
          <image
            class="human-diagram"
            xlink:href="../../../assets/img/stickFigure.svg"
            height="100%"
            width="100%"
          />
          <g
            v-if="type === 'event' || (safetyTickets && safetyTickets.length)"
            class="injury-points-back"
          >
            <InjuryPoint
              :cx="50"
              :cy="8"
              :type="type"
              :affected-areas="affectedAreas"
              :safety-tickets="safetyTickets"
              point-name="headBack"
            />
            <InjuryPoint
              :cx="50"
              :cy="20"
              :type="type"
              :affected-areas="affectedAreas"
              :safety-tickets="safetyTickets"
              point-name="neckBack"
            />
            <InjuryPoint
              :cx="50"
              :cy="30"
              :type="type"
              :affected-areas="affectedAreas"
              :safety-tickets="safetyTickets"
              point-name="upperBack"
            />
            <InjuryPoint
              :cx="50"
              :cy="40"
              :type="type"
              :affected-areas="affectedAreas"
              :safety-tickets="safetyTickets"
              point-name="midBack"
            />
            <InjuryPoint
              :cx="50"
              :cy="50"
              :type="type"
              :affected-areas="affectedAreas"
              :safety-tickets="safetyTickets"
              point-name="lowerBack"
            />
            <InjuryPoint
              :cx="43.5"
              :cy="72"
              :type="type"
              :affected-areas="affectedAreas"
              :safety-tickets="safetyTickets"
              point-name="leftUpperLeg"
            />
            <InjuryPoint
              :cx="42.5"
              :cy="86"
              :type="type"
              :affected-areas="affectedAreas"
              :safety-tickets="safetyTickets"
              point-name="leftLowerLeg"
            />
            <InjuryPoint
              :cx="41.5"
              :cy="97"
              :type="type"
              :affected-areas="affectedAreas"
              :safety-tickets="safetyTickets"
              point-name="leftHeel"
            />
            <InjuryPoint
              :cx="56.5"
              :cy="72"
              :type="type"
              :affected-areas="affectedAreas"
              :safety-tickets="safetyTickets"
              point-name="rightUpperLeg"
            />
            <InjuryPoint
              :cx="57.5"
              :cy="86"
              :type="type"
              :affected-areas="affectedAreas"
              :safety-tickets="safetyTickets"
              point-name="rightLowerLeg"
            />
            <InjuryPoint
              :cx="58.5"
              :cy="97"
              :type="type"
              :affected-areas="affectedAreas"
              :safety-tickets="safetyTickets"
              point-name="rightHeel"
            />
          </g>
        </svg>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import InjuryPoint from "@/components/zone_control/safety_body_diagram/InjuryPoint.vue";

export default {
  components: { InjuryPoint },
  props: ["type", "affectedAreas", "safetyTickets"]
};
</script>

<style lang="scss" scoped>
@import "~@/scss/variables";

.injury-points-front {
  transform: translateX(-16.5%) translateY(1px);
}

.injury-points-back {
  transform: translateX(-16.5%) translateY(-1px);
}

.human-diagram {
  filter: brightness(0) invert(1);
}

::v-deep {
  .circle-unselected {
    fill: var(--graphics-8-gray);
  }

  .circle-selected {
    fill: var(--bg-brand-contrast);
  }

  .first-aid {
    fill: var(--graphics-9-yellow);
  }

  .recordable {
    fill: var(--graphics-4-red);
  }

  text {
    fill: var(--text-contrast) !important;
  }
}

@include light_theme() {
  .human-diagram {
    filter: grayscale(1) brightness(1.2);
  }
}
</style>
