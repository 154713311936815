<template>
  <div v-if="text && text.length > textLength">
    <v-tooltip
      top
      right
      :max-width="maxWidth"
      :max-height="maxHeight"
    >
      <template #activator="{ on, attrs }">
        <span
          v-bind="attrs"
          class="text"
          v-on="on"
        >
          {{ truncateText(text) }}
        </span>
      </template>
      <div class="text-subtitle-2 color--text-primary">{{ text }}</div>
    </v-tooltip>
  </div>
  <fragment v-else>
    {{ text || $t("NA") }}
  </fragment>
</template>

<script>
import { Fragment } from "vue-fragment";

export default {
  name: "TextOverflowTooltip",
  components: {
    Fragment
  },
  props: {
    text: { type: String, required: true, default: "" },
    maxWidth: { type: String, required: false, default: "242" },
    maxHeight: { type: String, required: false, default: "68" },
    textLength: { type: Number, default: 13 }
  },
  setup({ textLength }) {
    const truncateText = (text) =>
      text.length > textLength ? text.substring(0, textLength) + "..." : text;

    return {
      truncateText
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/_variables";
.text {
  font-weight: inherit !important;
}

.v-tooltip__content {
  border: 1px solid var(--border-primary) !important;
  background-color: var(--bg-primary);
  transform: translateX(-25%);
  padding: 12px;
  border-radius: 8px;
}

.text-overflow {
  white-space: nowrap;
}
</style>
