export const AppRoutes = {
  machine: {
    name: "machine",
    url: (machine_id = ":machine_id", machine_pk = ":machine_pk") =>
      `/machine/${machine_id}/${machine_pk}`
  },

  operatorDashboard: {
    name: "operator-dashboard",
    url: (machine_id = ":machine_id", machine_pk = ":machine_pk") =>
      `/machine/${machine_id}/${machine_pk}/operator-dashboard`
  },

  machineSummary: {
    name: "machine-summary",
    url: (machine_id = ":machine_id", machine_pk = ":machine_pk") =>
      `/machine/${machine_id}/${machine_pk}/summary`
  },

  machineKpi: {
    name: "machine-kpi",
    url: (machine_id = ":machine_id", machine_pk = ":machine_pk") =>
      `/machine/${machine_id}/${machine_pk}/kpi`
  },

  machineEvents: {
    name: "machine-events",
    url: (machine_id = ":machine_id", machine_pk = ":machine_pk") =>
      `/machine/${machine_id}/${machine_pk}/events`
  },

  processMonitoring: {
    name: "process-monitoring",
    url: (machine_id = ":machine_id", machine_pk = ":machine_pk") =>
      `/machine/${machine_id}/${machine_pk}/process-monitoring`
  },

  machineAnomalies: {
    name: "machine-anomalies",
    url: (machine_id = ":machine_id", machine_pk = ":machine_pk") =>
      `/machine/${machine_id}/${machine_pk}/anomalies`
  },

  groupSummary: {
    name: "group",
    url: (
      level = ":level",
      machine_group_id = ":machine_group_id",
      machine_group_pk = ":machine_group_pk"
    ) => `/group/${level}/${machine_group_id}/${machine_group_pk}/dashboard-summary`
  },

  qualityEventsGroup: {
    name: "quality-events-group",
    url: (
      level = ":level",
      machine_group_id = ":machine_group_id",
      machine_group_pk = ":machine_group_pk"
    ) => `/group/${level}/${machine_group_id}/${machine_group_pk}/quality-events`
  },

  qualityEventsMachine: {
    name: "quality-events-machine",
    url: (machine_id = ":machine_id", machine_pk = ":machine_pk") =>
      `/machine/${machine_id}/${machine_pk}/quality-events`
  },

  groupStatus: {
    name: "group-status",
    url: (
      level = ":level",
      machine_group_id = ":machine_group_id",
      machine_group_pk = ":machine_group_pk"
    ) => `/group/${level}/${machine_group_id}/${machine_group_pk}/status`
  },

  groupRealtime: {
    name: "group-realtime",
    url: (
      level = ":level",
      machine_group_id = ":machine_group_id",
      machine_group_pk = ":machine_group_pk"
    ) => `/group/${level}/${machine_group_id}/${machine_group_pk}/realtime`
  },
  groupSummaryOld: {
    name: "group-summary",
    url: (
      level = ":level",
      machine_group_id = ":machine_group_id",
      machine_group_pk = ":machine_group_pk"
    ) => `/group/${level}/${machine_group_id}/${machine_group_pk}/summary`
  },

  groupZoneControl: {
    name: "zone-control",
    url: (
      level = ":level",
      machine_group_id = ":machine_group_id",
      machine_group_pk = ":machine_group_pk"
    ) => `/group/${level}/${machine_group_id}/${machine_group_pk}/zone_control/:safety?`
  },
  groupZoneControlNew: {
    name: "zone-control-new",
    url: (
      level = ":level",
      machine_group_id = ":machine_group_id",
      machine_group_pk = ":machine_group_pk"
    ) => `/group/${level}/${machine_group_id}/${machine_group_pk}/zone-control-new/:safety?`
  },
  groupTickets: {
    name: "tickets",
    url: (
      level = ":level",
      machine_group_id = ":machine_group_id",
      machine_group_pk = ":machine_group_pk"
    ) => `/group/${level}/${machine_group_id}/${machine_group_pk}/tickets/:input_tab?`
  },

  groupTicketsNew: {
    name: "group-tickets-new-p911",
    url: (
      level = ":level",
      machine_group_id = ":machine_group_id",
      machine_group_pk = ":machine_group_pk"
    ) => `/group/${level}/${machine_group_id}/${machine_group_pk}/tickets-new/`
  },

  groupTicketsP911: {
    name: "group-tickets-new",
    url: (
      level = ":level",
      machine_group_id = ":machine_group_id",
      machine_group_pk = ":machine_group_pk"
    ) => `/group/${level}/${machine_group_id}/${machine_group_pk}/tickets-new/p911`
  },

  groupTicketsLdms: {
    name: "group-tickets-new-ldms",
    url: (
      level = ":level",
      machine_group_id = ":machine_group_id",
      machine_group_pk = ":machine_group_pk"
    ) => `/group/${level}/${machine_group_id}/${machine_group_pk}/tickets-new/ldms`
  },

  machineTickets: {
    name: "machine-tickets-p911",
    url: (machine_id = ":machine_id", machine_pk = ":machine_pk") =>
      `/machine/${machine_id}/${machine_pk}/tickets`
  },

  machineTicketsP911: {
    name: "machine-tickets",
    url: (machine_id = ":machine_id", machine_pk = ":machine_pk") =>
      `/machine/${machine_id}/${machine_pk}/tickets/p911`
  },

  machineTicketsLdms: {
    name: "machine-tickets-ldms",
    url: (machine_id = ":machine_id", machine_pk = ":machine_pk") =>
      `/machine/${machine_id}/${machine_pk}/tickets/ldms`
  },

  groupQuality: {
    name: "quality",
    url: (
      level = ":level",
      machine_group_id = ":machine_group_id",
      machine_group_pk = ":machine_group_pk"
    ) => `/group/${level}/${machine_group_id}/${machine_group_pk}/quality`
  },
  irisIngestion: {
    name: "iris-ingestion",
    url: "/iris/portal"
  },
  irisChat: {
    name: "iris-chat",
    url: "/iris"
  },
  irisIngestionKnowledgePortalViewBase: {
    name: "iris-ingestion-knowledge-portal-view",
    url: ""
  },
  irisIngestionFiles: {
    name: "iris-ingestion-files",
    url: "files",
    meta: {
      breadcrumbs: ($t) => [
        {
          text: $t("IRIS"),
          disabled: false,
          name: "iris-chat"
        },
        {
          text: $t("Knowledge Creation Portal"),
          disabled: true,
          name: "iris-ingestion-files"
        }
      ]
    }
  },
  irisIngestionTypeKnowledge: {
    name: "iris-ingestion-type-knowledge",
    url: "review",
    meta: {
      breadcrumbs: ($t) => [
        {
          text: $t("IRIS"),
          disabled: false,
          name: "iris-chat"
        },
        {
          text: $t("Knowledge Creation Portal"),
          disabled: false,
          name: "iris-ingestion-files"
        },
        {
          text: $t("Review"),
          disabled: true,
          name: "iris-ingestion-type-knowledge"
        }
      ]
    }
  },
  irisIngestionKnowledgePortalUploadBase: {
    name: "iris-ingestion-knowledge-portal-upload",
    url: "upload",
    meta: {
      breadcrumbs: ($t) => [
        {
          text: $t("IRIS"),
          disabled: false,
          name: "iris-chat"
        },
        {
          text: $t("Knowledge Creation Portal"),
          disabled: false,
          name: "iris-ingestion-files"
        },
        {
          text: $t("Upload"),
          disabled: true,
          name: "iris-ingestion-knowledge-portal-upload"
        }
      ]
    }
  },
  irisIngestionKnowledgePortalUploadFiltersLayout: {
    name: "iris-ingestion-knowledge-portal-upload-with-filters",
    url: "",
    meta: {
      breadcrumbs: ($t) => [
        {
          text: $t("IRIS"),
          disabled: false,
          name: "iris-chat"
        },
        {
          text: $t("Knowledge Creation Portal"),
          disabled: false,
          name: "iris-ingestion-files"
        },
        {
          text: $t("Upload"),
          disabled: true,
          name: "iris-ingestion-knowledge-portal-upload"
        }
      ]
    }
  },
  irisIngestionUploadDropzone: {
    name: "iris-ingestion-dropzone",
    url: "drop-file",
    meta: {
      breadcrumbs: ($t) => [
        {
          text: $t("IRIS"),
          disabled: false,
          name: "iris-chat"
        },
        {
          text: $t("Knowledge Creation Portal"),
          disabled: false,
          name: "iris-ingestion-files"
        },
        {
          text: $t("Upload"),
          disabled: true,
          name: "iris-ingestion-knowledge-portal-upload"
        }
      ]
    }
  },
  irisIngestionUploadPreview: {
    name: "iris-ingestion-preview",
    url: "preview",
    meta: {
      breadcrumbs: ($t) => [
        {
          text: $t("IRIS"),
          disabled: false,
          name: "iris-chat"
        },
        {
          text: $t("Knowledge Creation Portal"),
          disabled: false,
          name: "iris-ingestion-files"
        },
        {
          text: $t("Upload"),
          disabled: false,
          name: "iris-ingestion-knowledge-portal-upload"
        },
        {
          text: $t("Preview"),
          disabled: true,
          name: "iris-ingestion-preview"
        }
      ]
    }
  },
  irisIngestionTypeKnowledgeEdit: {
    name: "iris-ingestion-type-knowledge-edit",
    url: "type-knowledge",
    meta: {
      breadcrumbs: ($t) => [
        {
          text: $t("IRIS"),
          disabled: false,
          name: "iris-chat"
        },
        {
          text: $t("Knowledge Creation Portal"),
          disabled: false,
          name: "iris-ingestion-files"
        },
        {
          text: $t("Upload"),
          disabled: false,
          name: "iris-ingestion-knowledge-portal-upload"
        },
        {
          text: $t("Type Knowledge"),
          disabled: true,
          name: "iris-ingestion-type-knowledge-edit"
        }
      ]
    }
  },
  irisIngestionTypeKnowledgeReview: {
    name: "iris-ingestion-type-knowledge-review",
    url: (tykId = ":tykId") => `type-knowledge/${tykId}/`,
    meta: {
      breadcrumbs: () => {}
    }
  },
  irisGlobalSettingsBase: {
    name: "iris-global-settings",
    url: "/iris/global-settings"
  },
  irisGlobalSettingsSidebarLayout: {
    name: "iris-global-settings-sidebar-layout",
    url: ""
  },
  irisGlobalSettingsAssistants: {
    name: "iris-global-settings-assistants",
    url: "assistants",
    meta: {
      breadcrumbs: ($t) => [
        {
          text: $t("IRIS"),
          disabled: false,
          name: "iris-chat"
        },
        {
          text: $t("Global Settings"),
          disabled: false,
          name: "iris-global-settings"
        },
        {
          text: $t("Assistants"),
          disabled: true,
          name: "iris-global-settings-assistants"
        }
      ]
    }
  },
  irisGlobalSettingsLlmReports: {
    name: "iris-global-settings-reports",
    url: "reports",
    meta: {
      breadcrumbs: ($t) => [
        {
          text: $t("IRIS"),
          disabled: false,
          name: "iris-chat"
        },
        {
          text: $t("Global Settings"),
          disabled: false,
          name: "iris-global-settings"
        },
        {
          text: $t("LLM Reports"),
          disabled: true,
          name: "iris-global-settings-reports"
        }
      ]
    }
  }
};
