<template>
  <ProductionSummary
    :machine-group-pk="machineGroupPk"
    :selected-machine-groups="selectedMachineGroups"
    :week-picker="weekPicker"
    :load-machine-production-data="loadMachineProductionData"
    :load-machine-group-production-data="loadMachineGroupProductionData"
    :loaded="loaded"
    :machine-data="machineData"
    :machine-headers="machineHeaders"
    :get-shift="getShift"
    :set-week-picker="setWeekPicker"
    :set-selected-machine-groups="setSelectedMachineGroups"
    :shifts="shifts"
    :days="days"
  />
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

import ProductionSummary from "./components/ZoneControlProductionSummary.vue";

export default {
  components: {
    ProductionSummary
  },
  data() {
    let current = moment().startOf("week");
    let days = [];
    let n = 7;
    while (n > 0) {
      days.push({
        slotName: "item." + current.format("ddd"),
        text: current.format("ddd"),
        value: current.format("ddd"),
        moment: current.clone(),
        align: "center",
        sortable: false
      });
      current.add(1, "day");
      n--;
    }
    return {
      selectedMachineGroups: [],
      weekPicker: [
        moment().startOf("week").format("YYYY-MM-DD"),
        moment().endOf("week").format("YYYY-MM-DD")
      ],
      loaded: false,
      days,
      shifts: [],
      machineData: [],
      machineHeaders: [
        { text: this.$t("Shift"), value: "shift", align: "center", sortable: false },
        { text: "", value: "labels", align: "center", sortable: false }
      ].concat(days),
      machineGroupIds: [],
      intervals: [
        { name: this.$t("Default"), value: "card" },
        { name: this.$t("Shift"), value: "shift" },
        { name: this.$t("Day"), value: "day" },
        { name: this.$t("Week"), value: "week" },
        { name: this.$t("Month"), value: "month" },
        { name: this.$t("Year"), value: "year" }
      ],
      selectedInterval: "card",
      level: this.$route.params.level,
      machine_group_id: this.$route.params.machine_group_id,
      machine_group_pk: this.$route.params.machine_group_pk
    };
  },
  computed: {
    ...mapGetters({
      shiftLookup: "dbCache/shiftFromPk",
      machineGroupLookup: "dbCache/machineGroupFromId",
      machines: "dbCache/machines"
    }),
    machineGroupPk() {
      return parseInt(this.machine_group_pk, 10);
    }
  },
  async created() {
    if (this.machineGroupIds.length == 1) {
      this.loadMachineProductionData();
    } else {
      this.loadMachineGroupProductionData();
    }
  },
  methods: {
    setSelectedMachineGroups(value) {
      this.selectedMachineGroups = value;
    },
    setWeekPicker(weekPicker) {
      this.weekPicker = weekPicker;
    },
    prepareForProductionDataFetching() {
      this.machineData = [];
      let start = moment(this.weekPicker[0]);
      let days = [];
      let n = 7;
      while (n > 0) {
        days.push({
          slotName: "item." + start.format("ddd"),
          text: start.format("ddd"),
          value: start.format("ddd"),
          moment: start.clone(),
          align: "center",
          sortable: false
        });
        start.add(1, "day");
        n--;
      }
      this.days = days;
      let params = {
        machine_group_id:
          this.selectedMachineGroups.length > 0
            ? this.selectedMachineGroups[this.selectedMachineGroups.length - 1].pk
            : this.machineGroupPk,
        scale: "1d",
        from_date: moment(this.weekPicker[0]).toISOString(),
        to_date: moment(this.weekPicker[1]).endOf("day").toISOString()
      };
      return params;
    },
    buildProductionData(data, totalsObj, type) {
      const summaryTotals = [];
      data.forEach((item) => {
        // console.log("item", item.key);
        const machineOrGroup =
          type === "machine" ? this.machinesLookup[item.key] : this.machineGroupLookup(item.key);
        if (machineOrGroup) {
          item.shifts.buckets.forEach((shift) => {
            shift["name"] = this.getShift(shift.key);
          });
          if (item.shifts.buckets.length > 0) {
            item.shifts.buckets.sort((a, b) =>
              a.name.toUpperCase() < b.name.toUpperCase()
                ? -1
                : a.name.toUpperCase() > b.name.toUpperCase()
                  ? 1
                  : 0
            );
          }
          if (!totalsObj.shifts && item.shifts.buckets) {
            totalsObj.shifts = JSON.parse(JSON.stringify(item.shifts.buckets));
            //when we parse the JSON, there are already values in
            // the produced and production_target fields
            for (const shift of totalsObj.shifts) {
              for (let bucket of shift.intervals.buckets) {
                bucket.produced.value = 0;
                bucket.scaled_target_2.production_target_2.value = 0;
              }
            }
          }
          let machineTotals = [];
          item.shifts.buckets.forEach((shift) => {
            // console.log("shift", shift.name);
            let shiftTotals = totalsObj.shifts.find((st) => st.key == shift.key);
            // ------------------------------------------ TODO: check
            // if (!shiftTotals && (type == "group" || machine.contributesToTotal)) {
            //   shiftTotals = JSON.parse(JSON.stringify(shift));
            //   totalsObj.shifts.push(shiftTotals);
            // }
            // ------------------------------------------
            if (shiftTotals) {
              // console.log("shiftTotal", shiftTotals);
              shift.intervals.buckets.forEach((shiftDay, dayIndex) => {
                // console.log(
                //   "shiftDays",
                //   shiftDay.key_as_string,
                //   shiftDay.produced.value,
                //   shiftDay.scaled_target_2.production_target_2.value
                // );
                if (!machineTotals[dayIndex]) {
                  machineTotals[dayIndex] = JSON.parse(JSON.stringify(shiftDay));
                } else {
                  machineTotals[dayIndex]["produced"]["value"] += shiftDay.produced.value;
                  machineTotals[dayIndex]["scaled_target_2"]["production_target_2"]["value"] +=
                    shiftDay.scaled_target_2.production_target_2.value;
                }
                if (type == "group" || machineOrGroup.contributesToTotal) {
                  if (!summaryTotals[dayIndex]) {
                    summaryTotals[dayIndex] = JSON.parse(JSON.stringify(shiftDay));
                  } else {
                    summaryTotals[dayIndex]["produced"]["value"] += shiftDay.produced.value;
                    summaryTotals[dayIndex]["scaled_target_2"]["production_target_2"]["value"] +=
                      shiftDay.scaled_target_2.production_target_2.value;
                  }
                  if (dayIndex in shiftTotals.intervals.buckets) {
                    const bucket = shiftTotals.intervals.buckets[dayIndex];
                    bucket.produced.value += shiftDay.produced.value;
                    bucket.scaled_target_2.production_target_2.value +=
                      shiftDay.scaled_target_2.production_target_2.value;
                  } else {
                    shiftTotals.intervals.buckets[dayIndex] = JSON.parse(JSON.stringify(shiftDay));
                  }
                }
              });
            }
          });
          item.shifts.buckets.push({
            key: "totals",
            intervals: {
              buckets: machineTotals
            }
          });
          this.machineData.push({
            name: machineOrGroup.name,
            level: machineOrGroup.level ? machineOrGroup.level.level : null,
            id: machineOrGroup.id,
            pk: machineOrGroup.pk,
            shifts: item.shifts.buckets
          });
        }
      });
      if (!totalsObj.shifts) {
        totalsObj.shifts = [];
      }
      if (totalsObj.shifts.length > 0) {
        totalsObj.shifts.sort((a, b) =>
          a.name.toUpperCase() < b.name.toUpperCase()
            ? -1
            : a.name.toUpperCase() > b.name.toUpperCase()
              ? 1
              : 0
        );
      }
      totalsObj.shifts.push({
        key: "totals",
        intervals: {
          buckets: summaryTotals
        }
      });
      if (this.machineData.length > 0) {
        this.machineData.sort((a, b) =>
          a.name.toUpperCase() < b.name.toUpperCase()
            ? -1
            : a.name.toUpperCase() > b.name.toUpperCase()
              ? 1
              : 0
        );
      }
      if (this.selectedMachineGroups.length > 0) {
        totalsObj["shifts"] = JSON.parse(JSON.stringify(this.selectedMachineGroups[0]["shifts"]));
      }
      this.machineData.unshift(totalsObj);
    },
    async loadMachineGroupProductionData() {
      this.loaded = false;
      const params = this.prepareForProductionDataFetching();
      try {
        const response = await this.$http.get("/metrics/production_per_group_per_shift/", {
          params
        });
        if (response && response.data && response.data.data && response.data.shifts) {
          const data = Object.keys(response.data.data).map((item) => ({
            ...response.data.data[item],
            key: parseInt(item, 10)
          }));
          this.shifts = response.data.shifts;
          const parentMachineGroup = this.machineGroupLookup(this.machineGroupPk);
          const totalsObj = {
            name:
              parentMachineGroup && parentMachineGroup.name
                ? parentMachineGroup.name
                : this.$t("Unknown Group"),
            id: parentMachineGroup.id,
            pk: parentMachineGroup.pk,
            level: parentMachineGroup.level,
            shifts: null
          };
          this.buildProductionData(data, totalsObj, "group");
          this.machineData = this.machineData.map((data) => {
            const group = this.machineGroupLookup(parseInt(data.pk, 10));
            return {
              ...data,
              hasSubGroups:
                group && group.allSubGroups && group.allSubGroups.length > 0 ? true : false
            };
          });
          if (this.selectedMachineGroups.length > 0) {
            this.machineData.splice(1, 0, ...this.selectedMachineGroups);
          }
        }
        this.loaded = true;
      } catch (error) {
        this.loaded = true;
        console.error(error);
      }
    },
    async loadMachineProductionData() {
      this.loaded = false;
      const params = this.prepareForProductionDataFetching();
      try {
        const response = await this.$http.get("metrics/production_per_machine_per_shift/", {
          params
        });
        this.shifts = response.data.shifts;
        const parentMachineGroup = this.machineGroupLookup(this.machineGroupPk);
        let totalsObj = {
          name:
            parentMachineGroup && parentMachineGroup.name
              ? parentMachineGroup.name
              : this.$t("Unknown Group"),
          id: -1,
          pk: false,
          shifts: null
        };
        this.buildProductionData(response.data.data, totalsObj, "machine");
        if (this.selectedMachineGroups.length > 0) {
          this.machineData.splice(1, 0, ...this.selectedMachineGroups);
        }
        this.loaded = true;
      } catch (error) {
        this.loaded = true;
        console.error(error);
      }
    },
    getShift(shift_id) {
      let shift = this.shiftLookup(shift_id);
      if (shift_id == "totals") {
        return this.$t("Totals");
      }
      if (shift) {
        return shift.name;
      }
      return this.$t("Unknown Shift") + " (" + shift_id + ")";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/views/ldms/zone-control/ZoneControl.scss";
</style>
