<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div
    key="summary"
    class="prod-summary tabSelected"
  >
    <div class="prod-header">
      <h2>{{ $t("Weekly Production Summary") }}</h2>
      <v-text-field
        v-model="machineSearch"
        clearable
        single-line
        hide-details
        prepend-inner-icon="mdi-magnify"
        :label="$t('Search')"
      ></v-text-field>
      <date-range-picker
        id="weekPicker"
        v-model="weekPickerValue"
        position="bottom right"
        @okClick="
          toggle == 'group' ? loadMachineGroupProductionData() : loadMachineProductionData()
        "
      ></date-range-picker>
    </div>
    <v-progress-linear
      v-if="!loaded"
      indeterminate
    ></v-progress-linear>
    <div
      v-if="loaded"
      class="machines"
    >
      <v-data-iterator
        :items="machineData"
        :search="machineSearch"
        :items-per-page="10"
      >
        <template #header> </template>
        <template #default="{ items }">
          <div
            v-for="(item, index) in items"
            :key="index"
            class="card machineCard"
          >
            <div
              v-if="item.pk"
              class="card-header"
            >
              <i
                v-if="showRemoveIcon(item)"
                class="fa fa-times remove-icon"
                @click="removeDrilldown(item)"
              />
              <h3
                v-if="!showDrilldownButton(item, index)"
                class="machine-click"
                @click="
                  $router.push({
                    name: 'machine',
                    params: { machine_id: item.id, machine_pk: item.pk }
                  })
                "
              >
                {{ item.name }}
              </h3>
              <h3
                v-if="showDrilldownButton(item, index)"
                class="machine-click"
                @click="
                  $router.push({
                    name: 'zone-control',
                    params: {
                      level: item.level,
                      machine_group_id: item.id,
                      machine_group_pk: item.pk
                    }
                  })
                "
              >
                {{ item.name }}
              </h3>
              <v-btn
                v-if="index == 0 && item.hasSubGroups"
                class="subgroup-breakdown-button"
                color="primary"
                @click="
                  $router.push({
                    name: 'subgroup-breakdown',
                    params: {
                      level: item.level.level,
                      machine_group_id: item.id,
                      machine_group_pk: item.pk
                    }
                  })
                "
              >
                <v-icon>fa-bar-chart</v-icon>
              </v-btn>
              <v-btn
                v-if="showDrilldownButton(item, index)"
                class="drilldown-button"
                color="primary"
                @click="handleDrilldownClick(item)"
              >
                {{ $t("Drilldown") }}
              </v-btn>
            </div>
            <div
              v-else
              class="card-header"
            >
              <h3>{{ item.name }}</h3>
              <v-btn-toggle
                v-if="showGroupMachineToggle"
                v-model="toggle"
                class="toggle-buttons"
                group
                color="primary"
              >
                <v-btn value="group">
                  {{ $t("Group") }}
                </v-btn>
                <v-btn value="machine">
                  {{ $t("Machines") }}
                </v-btn>
              </v-btn-toggle>
              <v-tabs
                v-if="showGroupMachineToggle"
                v-model="activeTab"
                class="smallPageTabs"
              >
                <v-tab class="smalltab">{{ $t("Group") }}</v-tab>
                <v-tab class="smalltab">{{ $t("Machines") }}</v-tab>
              </v-tabs>
            </div>
            <div class="card-content">
              <div class="summary">
                <div class="item">
                  <p class="label">{{ $t("Demand") }}</p>
                  <p></p>
                </div>
                <div class="item">
                  <p class="label">{{ $t("Target") }}</p>
                  <p>{{ getMachineTotalTarget(item) }}</p>
                </div>
                <div class="item">
                  <p class="label">{{ $t("Produced") }}</p>
                  <p>{{ getMachineTotalProduced(item) }}</p>
                </div>
              </div>
              <v-data-table
                :headers="machineHeaders"
                item-key="key_as_string"
                :items="item.shifts"
                disable-pagination
                fixed-header
                hide-default-footer
                multi-sort
              >
                <template #[`item.shift`]="{ item }">
                  <p
                    :class="{ total: item.key == 'totals' }"
                    :style="{ color: getShiftColor(item.key) }"
                  >
                    {{ getShift(item.key) }}
                  </p>
                </template>
                <template #[`item.labels`]>
                  <p>{{ $t("Actual") }}</p>
                  <p>{{ $t("Target") }}</p>
                </template>
                <template
                  v-for="(day, dayIndex) in days"
                  #[day.slotName]="{ item }"
                >
                  <p
                    :key="dayIndex + 'production'"
                    :class="getDayClass(day, item.intervals.buckets)"
                  >
                    {{ getProductionData(day, item.intervals.buckets) }}
                  </p>
                  <p :key="dayIndex + 'target'">{{ getTargetData(day, item.intervals.buckets) }}</p>
                </template>
              </v-data-table>
            </div>
          </div>
        </template>
      </v-data-iterator>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";

import DateRangePicker from "@/components/form/DateRangePicker";
import { shorten } from "@/utils/filters";

export default {
  name: "ProductionSummary",
  components: {
    DateRangePicker
  },
  props: {
    machineGroupPk: { type: Number, default: undefined },
    selectedMachineGroups: { type: Array, default: () => [] },
    weekPicker: { type: Array, default: () => [] },
    loadMachineProductionData: { type: Function, default: () => null },
    loadMachineGroupProductionData: { type: Function, default: () => null },
    loaded: { type: Boolean, default: false },
    machineData: { type: Array, default: () => [] },
    machineHeaders: { type: Array, default: () => [] },
    getShift: { type: Function, default: () => null },
    setWeekPicker: { type: Function, default: () => null },
    setSelectedMachineGroups: { type: Function, default: () => null },
    shifts: { type: Array, default: () => [] },
    days: { type: Array, default: () => [] }
  },
  data() {
    return {
      machineSearch: "",
      activeTab: 1,
      toggle: "group"
    };
  },
  computed: {
    ...mapGetters({
      machineGroupLookup: "dbCache/machineGroupFromId"
    }),
    showGroupMachineToggle() {
      const machineGroup = this.machineGroupLookup(this.machineGroupPk);
      if (machineGroup && machineGroup.allSubGroups && machineGroup.allSubGroups.length > 0) {
        return true;
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.toggle = "machine";
        return false;
      }
    },
    weekPickerValue: {
      get() {
        return this.weekPicker;
      },
      set(value) {
        let start = moment(value[0]).startOf("week");
        let end = moment(value[0]).endOf("week");
        const week = [start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD")];
        this.setWeekPicker(week);
      }
    }
  },
  watch: {
    toggle() {
      this.setSelectedMachineGroups([]);
      this.activeTab = this.toggle === "machine" ? 1 : 0;
      this.toggle === "machine"
        ? this.loadMachineProductionData()
        : this.loadMachineGroupProductionData();
    }
  },
  methods: {
    showRemoveIcon(machineOrGroup) {
      const foundMachineOrGroup = this.selectedMachineGroups.find(
        (item) => item.pk === machineOrGroup.pk
      );
      return "hasSubGroups" in machineOrGroup && foundMachineOrGroup ? true : false;
    },
    showDrilldownButton(machineOrGroup, index) {
      return (
        this.toggle === "group" &&
        index !== 0 &&
        "hasSubGroups" in machineOrGroup &&
        (this.selectedMachineGroups.length > 0
          ? this.selectedMachineGroups.find((group) => group.pk === machineOrGroup.pk)
            ? false
            : true
          : true)
      );
    },
    removeDrilldown(group) {
      const foundGroupIndex = this.selectedMachineGroups.findIndex((item) => item.pk === group.pk);
      if (foundGroupIndex >= 0) {
        // eslint-disable-next-line vue/no-mutating-props
        this.selectedMachineGroups.splice(foundGroupIndex);
      }
      this.loadMachineGroupProductionData();
    },
    handleDrilldownClick(machineOrGroup) {
      // eslint-disable-next-line vue/no-mutating-props
      this.selectedMachineGroups.push(machineOrGroup);
      if ("hasSubGroups" in machineOrGroup && machineOrGroup.hasSubGroups) {
        this.loadMachineGroupProductionData();
        return;
      }
      this.loadMachineProductionData();
    },
    getShiftColor(shift_id) {
      let shift = this.shifts.find((s) => s.id === shift_id);
      if (shift) {
        return shift.color;
      }
      return "";
    },
    getDayClass(day, machineShift) {
      let prod = 0;
      let target = 0;
      machineShift.forEach((dayProd) => {
        let d = moment(dayProd.key_as_string);
        if (d.isSame(day.moment, "day")) {
          prod = dayProd.produced.value;
          target = dayProd.scaled_target_2.production_target_2.value;
        }
      });
      return prod < target ? "below" : "above";
    },
    getProductionData(day, machineShift) {
      if (day.moment.isAfter(moment())) {
        return "";
      }
      let prod = 0;
      machineShift.forEach((dayProd) => {
        let d = moment(dayProd.key_as_string);
        if (d.isSame(day.moment, "day")) {
          prod = dayProd.produced.value;
        }
      });
      return shorten(prod);
    },
    getTargetData(day, machineShift) {
      if (day.moment.isAfter(moment())) {
        return "";
      }
      let target = 0;
      machineShift.forEach((dayProd) => {
        let d = moment(dayProd.key_as_string);
        if (d.isSame(day.moment, "day")) {
          target = dayProd.scaled_target_2.production_target_2.value;
        }
      });
      return shorten(target);
    },
    getMachineTotalProduced(machine) {
      let total = 0;
      machine.shifts[machine.shifts.length - 1].intervals.buckets.forEach((day) => {
        total += day.produced.value;
      });
      return shorten(total);
    },
    getMachineTotalTarget(machine) {
      let total = 0;
      machine.shifts[machine.shifts.length - 1].intervals.buckets.forEach((day) => {
        total += day.scaled_target_2.production_target_2.value;
      });
      return shorten(total);
    }
  }
};
</script>
